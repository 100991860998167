<template lang="pug">
.content
  div Eurojackpot Simulation
  .params
    labeled(caption="Ab Jahr")
      select.year(v-model="year")
        option(v-for="y in years", :value="y") {{ y }}
    labeled(caption="Felder")
      select.fields(v-model="fields")
        option(v-for="c in 9") {{ c }}
    icon.button.small(name="play", @click="runSimulation()")

  div(v-if="simulated && simulated.total")
    div(style="display:flex;margin:0 4vw;", :style="{ color: simulated.total < simulated.spent ? 'red' : 'green' }") 
        div(style="flex:1 0;text-align:right;padding:0 2vw;") Gewinn 
        div(style="width:20vw;text-align:right;") {{simulated.total.toFixed(2)}} €
    div(style="display:flex;margin:0 4vw;")
        div(style="flex:1 0;text-align:right;padding:0 2vw;") Kosten
        div(style="width:20vw;text-align:right;") {{simulated.spent.toFixed(2)}} €
  .grid(v-if="simulated.ticket && simulated.ticket.fields")
    eurojackpot-grid(v-for="r in simulated.ticket.fields",:crossed="r",:cfg="{ grid: 1, small:1 }")
  div(style="flex:1 0;overflow: auto;")
    .winblock(v-for="w in simulated.wins")
        .windate {{w.date}}
        .win(v-for="x in w.win") 
            div {{x.class}}
            div(style="text-align:right;") {{x.count}}
            div(style="text-align:right;") {{x.win.toFixed(2)}} €
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import EurojackpotGrid from "@/components/EurojackpotGrid.vue";
import Labeled from "@/components/Labeled.vue";
export default {
  components: { Icon, Button3d, EurojackpotGrid, Labeled },
  data() {
    return {
      years: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
      year: 2025,
      fields: 6,
    };
  },
  computed: {
    ...mapGetters({
      simulated: "eurojackpot/simulated"
    }),
  },
  methods: {
    ...mapActions({
      simulation: "eurojackpot/simulation",
    }),
    runSimulation() {
      const cfg = { year: this.year, count: this.fields };
      this.simulation(cfg);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  color: black;
  font-size: 4vw;
  overflow:auto;
}
.params {
  display: flex;
  flex-flow: row;
  align-self: stretch;
  margin: 3vw 5vw;
  align-items: center;
}
.labeled {
  margin: 0 2vw;
  color: #666;
  font-size: 3vw;
}
.year {
  font-size: 4vw;
  padding: 0.25vw 0.5vw;
}
.fields {
  width: 6rem;
  text-align: right;
  font-size: 4vw;
  padding: 0.25vw 0.5vw;
}
.grid {
    flex: 1 0;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center;
    overflow:auto;
}
.winblock {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    margin: 1vw 4vw;
}
.windate {
    text-align: left;
    background-color: #e0e8ef;
    padding: 1vw 4vw;
}
.win {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
}
</style>
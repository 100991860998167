<template lang="pug">
.navi
    //- .item(@click="goto('media')") Media
    .item(@click="goto('tools')") Tools
    .item(@click="goto('demos')") Demos
    .item(@click="goto('about')") Über mich
    .item(@click="goto('impressum')") Impressum
    .item(@click="goto('contact')") Kontakt
    //- .item Login
</template>

<script>
export default {
   methods: {
       goto(target) {
           this.$router.push({ name: target }).catch(err => {});
       }
   }
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';
    .navi {
        flex: 0 0 auto;
        display:flex;
        flex-flow:row nowrap;
        background-color: transparent;
        // height: 2rem;
        padding: 0.1rem 1rem;
        overflow:auto;
        justify-content: flex-end;
        align-items: center;
    }
    // .naviContent {
    //     flex: 0 0 auto;
    //     display: flex;
    //     align-items: center;
    //     flex-flow: column nowrap;
    //     overflow:auto;
    // }
    .item {
        color: @c5;
        font-weight: bold;
        margin: 0.2rem 1rem;
        cursor: pointer;
        font-size: 3.3vw;
    }
@media print {

  .navi {
    display: none !important;
  }
}
</style>
</style>
<template lang="pug">
#app
  top-header
  navi
  //- login(v-if="toLogin")
  #content
    router-view
  xFooter
  //- #nav
  //-   #router-link(to="/") Home
  //-   #router-link(to="/about") About
    
</template> 

<script>
import TopHeader  from '@/components/TopHeader.vue';
import Navi from '@/components/Navi.vue';
import XFooter from '@/components/Footer.vue';
import api from './api';
import * as crypto from 'crypto';
// import Login from '@/components/Login.vue';

export default {

  components: {
    TopHeader,
    Navi,
    XFooter,
    // Login
  },
  data() {
    return {
      toLogin: true
    }
  },
  computed: {
    isAuthenticated() {
      return true;
    }
  },
  metaInfo: {
    title: 'Softwareentwicklung',
    titleTemplate: 'Gabriel Gnatowski, %s',
    meta: [
      { description: 'Als leidenschaftlicher Entwickler mit Fachkenntnissen in Node.js, JavaScript und TypeScript bringe ich innovative Ideen zum Leben. Mit einem Auge für Details und einer klaren Fokussierung auf effiziente Lösungen strebe ich danach, nutzerfreundliche und skalierbare Anwendungen zu entwickeln. Meine Begeisterung für Technologie und kontinuierliches Lernen treibt mich an, stets auf dem neuesten Stand zu bleiben und die besten Programmierpraktiken in meine Arbeit einzubringen. Ich freue mich darauf, Ihre Projekte mit Expertise und Kreativität zu unterstützen' },
      { "google-site-verification": "gNZdOFTlRb5m-8sKm5HpoKmW3c5E-dOpOybps0yllvs" }
    ],
    link: [
      { rel: 'favicon', href: 'favicon-32x32.png' }
    ]
  },
  async mounted() {
    const device = localStorage.getItem('device');
    if (!device) {
      console.log('CRYPTO:', crypto);
      const id = crypto.randomBytes(20).toString('hex');
      const r = await api.auth.register({
        device_id: id,
        user_agent: navigator.userAgent,
        platform: navigator.platform
      })
      localStorage.setItem('device', id);
    }
  }
}

</script>
<style lang="less">
@import './shared.less';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  display:flex;
  flex-flow:column;
}

#app {
  flex:1 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: @c3;
  background-color: #fff;
  position: relative;
  height: 100%;
}

#content {
  flex: 1 0;
  display:flex;
  overflow:hidden;
}

@media (max-width:700px) {
  html, body {
    font-size: 0.6rem;
  }
  #content {
    flex: 1 0;
    display:flex;
    flex-flow: column nowrap;
    overflow:auto;
  }
}

@media print {
    html, body {
        height: auto !important;
    }
    #app {
        height: auto !important;
    }
}
</style>

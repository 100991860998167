"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navi = void 0;
const api_js_1 = require("@/api.js");
const state = {
    items: [
        { name: 'Kontakt', target: 'contact' },
        { name: 'Impressum', target: 'impressum' },
        // { name: 'Login', target: 'login' },
    ]
};
const mutations = {
    set_menu(state, items) {
        state.items = items;
    },
};
const getters = {
    items: state => state.items
};
const actions = {
    async menu({ commit }, data) {
        console.log('MENU:', data);
        const r = await api_js_1.default.navi.menu(data);
        console.log('started', r.data);
        commit('set_menu', r.data);
    },
};
exports.Navi = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};

import { render, staticRenderFns } from "./Labeled.vue?vue&type=template&id=63f83854&scoped=true&lang=pug"
import script from "./Labeled.vue?vue&type=script&lang=js"
export * from "./Labeled.vue?vue&type=script&lang=js"
import style0 from "./Labeled.vue?vue&type=style&index=0&id=63f83854&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f83854",
  null
  
)

export default component.exports
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pentomino = void 0;
const state = {
    count: 0,
    solutions: []
};
const mutations = {
    add(state, item) {
        state.count = item.count;
        state.solutions.push(item.stack);
    }
};
const getters = {
    count: (state) => state.count,
    solutions: (state) => state.solutions
};
const actions = {
    add({ commit }, item) {
        commit('add', item);
    }
};
exports.Pentomino = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};

<template lang="pug">
    .top
        .logo(:style="logo",@click="$router.push({ name: 'home' })")
        .header(:style="header")
            .names
                .title Gabriel Gnatowski
                .sub Softwareentwicklung
        slot
</template>

<script>
export default {
    computed: {
        logo() {
            return {
                backgroundImage: "url(" + require('../assets/gg.png') + ")"
            };
        },
        header() {
            return {
                // backgroundImage: "url(" + require('../assets/header.png') + ")"
            };
        }
    }
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';
.top {
    flex:0 0 auto;
    display:flex;
    flex-flow:row nowrap;
    align-items:stretch;
    // height:14rem;
    // position: relative;
    align-items: center;
    margin: 1rem;
    box-shadow: 3px 3px 5px #444;
}

.logo {
    flex: 0 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 6rem;
    height: 6rem;
    margin: 0.5rem 1rem;
    cursor: pointer;
}
.header {
    // background-color: #eee;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0;
    // position: absolute;
    // margin-left:4rem;
    // top: 0;
    // left: 6rem;
    // height: 4rem;
    // right: 0;
    display: flex;
    align-items: center;
}
.names {
    flex:1 0;
    display:flex;
    flex-flow:column;
    position:relative;
    align-items:baseline;
    padding:1rem 2rem;
}
.title {
    // position:absolute;
    // left:1rem;
    // top:0.5rem;
    font-size:4vw;
    color:@c1;
    // text-shadow: #fff 0 0 4px;
    // padding:0.5rem;
}

.sub {
    color:@c1;
    font-size: 3vw;
    // text-shadow: #fff 0 0 2px;
}

@media (orientation: landscape ) {
    .top {
        // height: 8rem;
        background-color: #eee;
    }
    .logo {
        width: 5rem;
        height: 5rem;
        padding: 1rem;
        margin: 0.5rem 0.5rem;
    }
    .header {
        // left: 2.5rem;
        // height: 2rem;
        align-items: center;
    }
    .names {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-flex-start;
    }
    .title {
        font-size:2rem;
        padding: 0 2rem;
    }
    .sub {
        font-size:1.6rem;
        // margin: 1rem 1rem;
    }
}

@media print {
    .top {
        display: none;
    }
}
</style>
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Diary = void 0;
const api_js_1 = require("@/api.js");
const state = {
    activeTaskId: null,
    last: [],
    tasks: [],
    dates: []
};
const mutations = {
    set_task(state, taskId) {
        state.activeTaskId = taskId;
    },
    clear_task(state) {
        state.activeTaskId = null;
    },
    list(state, tasks) {
        state.tasks = tasks;
    },
    last24h(state, tasks) {
        state.last = tasks;
        const activeTask = tasks.find(task => task.stop === '00:00:00'); // Aktive Tätigkeit ermitteln
        state.activeTaskId = activeTask ? activeTask.id : null;
    },
    dates(state, dates) {
        state.dates = dates;
    }
};
const getters = {
    dates: (state) => state.dates,
    last: (state) => state.last,
    tasks: (state) => state.tasks,
    activeTaskId: (state) => state.activeTaskId
};
const actions = {
    async start({ commit }, data) {
        console.log('START:', data);
        const r = await api_js_1.default.diary.start(data.groupId, data.taskId, data.helpId);
        console.log('started', r.data.id);
        commit('set_task', r.data.id);
    },
    async stop({ commit, state }, id) {
        console.log('stop:', id, state.activeTaskId);
        if (state.activeTaskId) {
            await api_js_1.default.diary.stop(id);
            commit('clear_task');
        }
    },
    async getDates({ commit }) {
        const res = await api_js_1.default.diary.dates();
        commit('dates', res.data);
    },
    async list({ commit }, date) {
        const res = await api_js_1.default.diary.list(date);
        commit('list', res.data);
    },
    async last24h({ commit }) {
        const res = await api_js_1.default.diary.last24h();
        commit('last24h', res.data);
    }
};
exports.Diary = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lotto = void 0;
const api_js_1 = require("@/api.js");
const state = {
    items: [],
    draw: {},
    sim: {},
    predicts: []
};
const mutations = {
    set_items(state, items) {
        state.items = items;
    },
    // list(state, items) {
    //     state.items = items;
    // },
    drawing(state, draw) {
        state.draw = draw;
    },
    sim(state, sim) {
        state.sim = sim;
    },
    set_predicts(state, predicts) {
        state.predicts = predicts;
    }
};
const getters = {
    items: (state) => state.items,
    draw: state => state.draw,
    simulated: state => state.sim,
    predicts: state => state.predicts
};
const actions = {
    // async list({ commit }) {
    //     const res = await api.tools.lotto();
    //     commit('list', res.data);
    // },
    async load({ commit }) {
        const res = await api_js_1.default.lotto.load();
        commit('set_items', res.data);
    },
    async drawing({ commit }, date) {
        console.log('DRAWING', date);
        const dayOfWeek = date.getDay(); // 0 = Sonntag, 1 = Montag, ..., 6 = Samstag
        // Ziehungstage: 3 = Mittwoch, 6 = Samstag
        const DRAWING_DAYS = [3, 6];
        // Suche den letzten Ziehungstag vor oder an diesem Datum
        let daysToSubtract = 0;
        if (dayOfWeek >= 6) {
            // Samstag oder später: letzte Ziehung war an diesem Samstag
            daysToSubtract = dayOfWeek - 6;
        }
        else if (dayOfWeek >= 3) {
            // Mittwoch oder später: letzte Ziehung war an diesem Mittwoch
            daysToSubtract = dayOfWeek - 3;
        }
        else {
            // Vor Mittwoch: letzte Ziehung war der Samstag davor
            daysToSubtract = dayOfWeek + 1; // +1, weil Samstag = 6
        }
        // Ziehungstag berechnen
        const drawingDate = new Date(date);
        drawingDate.setDate(date.getDate() - daysToSubtract);
        // Unix-Timestamp in Millisekunden
        const ut = drawingDate.getTime();
        console.log('ut=', ut);
        const resp = await api_js_1.default.lotto.drawing(ut);
        console.log('response:', resp.data);
        commit('drawing', (resp.data.length && resp.data[0]) || []);
    },
    async simulation({ commit }, cfg) {
        console.log('vuex.cfg', cfg);
        const resp = await api_js_1.default.lotto.simulation(cfg);
        commit('sim', resp.data);
    },
    async load_predicts({ commit }) {
        const resp = await api_js_1.default.lotto.predicts();
        commit('set_predicts', resp.data);
    }
};
exports.Lotto = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};

<template lang="pug">
div(
  style="display: flex; flex-flow: column; padding: 0 4vw; align-items: stretch; color: black; font-size: 3vw"
)
  h1(style="font-size: 5vw") Pflegetagebuch
  .action.noprint
    div(
      style="margin: 4vw; padding: 2vw; background-color: #cfc; border: 1px solid silver; display: grid; grid-template-columns: 1fr 4fr; font-size: 4vw; self-align: center; gap: 2vw; margin: 4vw 0; align-items: center"
    )
      div(style="width: 16vw; text-align: left") Gruppe
      select(v-model="groupId", @change="taskId = null")
        option(v-for="g in groups", :key="g.id", :value="g.id") {{ g.name }}
      div(style="width: 16vw; text-align: left") Tätigkeit
      select(v-model="taskId", :disabled="filteredTasks.length === 0")
        option(v-for="a in filteredTasks", :key="a.id", :value="a.id") {{ a.name }}
      div(style="width: 16vw; text-align: left") Hilfe
      select(v-model="helpId", style="width: 60vw")
        option(v-for="h in help", :key="h.id", :value="h.id") {{ h.name }}
      div(
        style="margin-top: 4vw; grid-column: span 2; align-self: center; display: flex; align-items: center; justify-content: space-evenly"
      )
        icon.button(
          name="play",
          @click="start()",
          :class="{ disabled: !canStart }"
        )
        icon.button(
          name="stop",
          @click="stop()",
          style="margin: 0 8vw",
          :class="{ disabled: !activeTask }"
        )
        div(style="flex: 1 0; font-size: 10vw")
          div(v-if="activeTask") {{ elapsed }}
    div(style="display: flex; align-items: center; margin: 4vw 0")
      div
        select(v-model="selectedDate", @change="loadTasks()")
          option(v-for="d in dates", :value="d") {{ d }}
        div(style="font-size: 4vw; text-align: left; margin: 1vw 0") {{ dayTime ? dayTime + " Minuten" : " " }}
      icon.button(
        name="print",
        style="margin: 2vw",
        :class="{ disabled: !selectedDate }",
        @click="print()"
      )

    care-grid(:tasks="grouped")

  .print
    div Datum: {{ selectedDate }}
    care-grid(:tasks="dayTasks")
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import CareGrid from "@/components/CareGrid.vue";

export default {
  components: { Icon, CareGrid },
  data() {
    return {
      groups: [
        { id: 1, name: "Körperpflege" },
        { id: 2, name: "Mobilität" },
        { id: 3, name: "Hauswirtschaft" },
      ],
      actions: [
        { groupId: 1, id: 11, name: "Duschen" },
        { groupId: 1, id: 12, name: "Baden" },
        { groupId: 1, id: 13, name: "Einkremen" },

        { groupId: 2, id: 21, name: "Begleiten" },

        { groupId: 3, id: 31, name: "Einkaufen" },
        { groupId: 3, id: 32, name: "Essen zubereiten" },
        { groupId: 3, id: 33, name: "Kochen" },
        { groupId: 3, id: 34, name: "Spülen" },
        { groupId: 3, id: 35, name: "Wohnnung reinigen" },
        { groupId: 3, id: 36, name: "Wechsel der Wäsche" },
      ],
      help: [
        { id: 1, name: "Anleitung" },
        { id: 2, name: "Beaufsichtigung" },
        { id: 3, name: "Unterstützung" },
        { id: 4, name: "Teilweise Übernahme" },
        { id: 5, name: "Volständige Übernahme" },
      ],
      groupId: null,
      taskId: null,
      helpId: null,
      interval: null,
      elapsed: null,
      selectedDate: null,
      grouped: null,
      dayTasks: null,
      dayTime: 0,
      wakelock: null,
    };
  },
  computed: {
    ...mapGetters({
      activeTaskId: "diary/activeTaskId",
      tasks: "diary/tasks",
      last: "diary/last",
      dates: "diary/dates",
    }),
    activeTask() {
      const t =
        this.activeTaskId && this.last.find((x) => x.id == this.activeTaskId);
      return t;
    },
    canStart() {
      return (
        this.groupId > 0 &&
        this.taskId > 0 &&
        this.helpId > 0 &&
        !this.activeTask
      );
    },
    filteredTasks() {
      const f = this.actions.filter((x) => x.groupId === this.groupId);
      return f;
    },
    minDate() {
      return this.getMinMaxDates(this.dates).minDate.split("T")[0];
    },
    maxDate() {
      return this.getMinMaxDates(this.dates).maxDate.split("T")[0];
    },
  },
  async created() {
    // const date = new Date().toISOString().split("T")[0];
    await this.init();
  },
  mounted() {
    this.requestWakeLock();

    document.addEventListener("visibilitychange", async () => {
      if (this.wakeLock !== null && document.visibilityState === "visible") {
        await this.requestWakeLock();
      }
    });

    this.interval = setInterval(() => {
      this.activeTask &&
        (this.elapsed = this.formatElapsedTime(this.activeTask.start));
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);

    // Wake Lock freigeben, wenn die Komponente unmountet wird
    this.releaseWakeLock();

    // Listener entfernen
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  },
  methods: {
    ...mapActions({
      list: "diary/list",
      last24h: "diary/last24h",
      startTask: "diary/start",
      stopTask: "diary/stop",
      getDates: "diary/getDates",
    }),
    async init() {
      await this.last24h();
      await this.getDates();
      if (this.activeTaskId) {
        const t = this.last.find((x) => x.id == this.activeTaskId);
        if (t) {
          this.groupId = t.groupId;
          this.taskId = t.taskId;
          this.helpId = t.helpId;
        }
      }
      // this.$set(this, 'grouped', this.groupTasks(this.last));
      this.grouped = this.groupTasks(this.last);
    },
    async requestWakeLock() {
      try {
        if ("wakeLock" in navigator) {
          wakeLock = await navigator.wakeLock.request("screen");
          console.log("Wake Lock aktiviert");

          // Falls der Wake Lock verloren geht (z. B. durch Inaktivität), neu anfordern
          wakeLock.addEventListener("release", () => {
            console.log("Wake Lock wurde freigegeben");
          });
        } else {
          console.warn("Wake Lock API wird nicht unterstützt");
        }
      } catch (err) {
        console.error(`Wake Lock Fehler: ${err.name}, ${err.message}`);
      }
    },
    releaseWakeLock() {
      if (this.wakeLock) {
        this.wakeLock.release().then(() => {
          console.log("Wake Lock manuell freigegeben");
          this.wakeLock = null;
        });
      }
    },
    print() {
      window.print();
    },
    async loadTasks() {
      console.log("selected date:", this.selectedDate);
      await this.list(this.selectedDate);
      this.dayTasks = this.groupTasks(this.tasks);
      this.dayTime = 0;
      console.log("DAYTASKS:", this.dayTasks);
      const t = {};
      this.dayTasks.forEach((x) => {
        x.tasks.forEach((z) => {
          t[z.name] = t[z.name] || 0;
          t[z.name] += z.time;
          this.dayTime += z.time;
        });
      });
      console.log("DAYTASKS:", t);
      //   this.$set(this, 'dayTasks', this.groupTasks(this.tasks));
    },
    getMinMaxDates(dates) {
      if (dates.length < 1) return { minDate: "", maxDate: "" };
      const sortedDates = dates
        .map((date) => new Date(date))
        .sort((a, b) => a - b);
      return {
        minDate: sortedDates[0].toISOString(),
        maxDate: sortedDates[sortedDates.length - 1].toISOString(),
      };
    },
    formatElapsedTime(startTime) {
      const start = new Date();
      const [hours, minutes, seconds] = startTime.split(":").map(Number);
      start.setHours(hours, minutes, seconds);
      const now = new Date();
      let diff = Math.floor((now - start) / 1000);

      const hh = String(Math.floor(diff / 3600)).padStart(2, "0");
      diff %= 3600;
      const mm = String(Math.floor(diff / 60)).padStart(2, "0");
      const ss = String(diff % 60).padStart(2, "0");
      console.log("elapsed", `${hh}:${mm}:${ss}`);
      return `${hh}:${mm}:${ss}`;
    },
    timeDifferenceInMinutes(startTime, endTime) {
      // Zeiten in Minuten umwandeln
      const toMinutes = (time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return hours * 60 + minutes + seconds / 60;
      };

      let startMinutes = toMinutes(startTime);
      let endMinutes = toMinutes(endTime);

      // Falls die Endzeit nach Mitternacht liegt
      if (endMinutes < startMinutes) {
        endMinutes += 24 * 60; // 24 Stunden zu Endzeit hinzufügen
      }

      // Differenz berechnen und aufrunden
      let diff = Math.round(endMinutes - startMinutes);
      if (diff < 1)
        diff = 1;
      return diff;  
    },
    groupTasks(tasks) {
      console.log("TASKS:", tasks);
      if (!tasks || tasks.length < 1) return {};

      const groups = {};
      tasks.forEach((x) => {
        const g = this.groups.find((z) => z.id == x.groupId);
        const t = this.actions.find((z) => z.id == x.taskId);
        const h = this.help.find((z) => z.id == x.helpId);
        groups[g.id] = groups[g.id] || {
          id: g.id,
          name: g.name,
          tasks: [],
        };
        const time = this.timeDifferenceInMinutes(x.start, x.stop);
        const item = {
          groupId: g.id,  
          id: t.id,
          date: x.date,
          name: t.name,
          help: h.name,
          helpId: h.id,
          time,
          start: x.start,
          stop: x.stop,
        };
        console.log('ITEM:', item);
        groups[g.id].tasks.push(item);
      });
      return Object.keys(groups).map((g) => groups[g]);
    },
    group(id) {
      return Object.keys(this.actions).filter((a) => a.groupId == id);
    },
    async start() {
      this.elapsed = 0;
      await this.startTask({
        groupId: this.groupId,
        taskId: this.taskId,
        helpId: this.helpId,
      });
      await this.init();
    },
    async stop() {
      await this.stopTask(this.activeTaskId);
      await this.init();
    },
  },
};
</script>
<style lang="less" scoped>
.table {
  display: grid;
  grid-template-columns: 4fr 3fr 2fr;
  font-size: 3vw;
  color: black;
  grid-gap: 1px;
  align-self: center;
}
.ptable {
  display: grid;
  grid-template-columns: 6fr repeat(4, 2fr) 3fr;
  font-size: 2vw;
  color: black;
  grid-gap: 1px;
}
select {
  font-size: 4vw;
  width: 60vw;
  padding: 1vw 2vw;
}
.item {
  border: 1px solid silver;
  padding: 2vw 1vw;
}
.item4 {
  grid-column: span 4;
}
.print {
  display: none;
}
@media print {
  .print {
    display: grid;
  }
  .noprint {
    display: none;
  }
}
</style>
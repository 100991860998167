<template lang="pug">
div(style="display: flex; flex-flow: column")
  .number(v-if="cfg.grid",:class="{ small: cfg.small }") {{ id }}
  .grid(v-if="cfg.grid")
    .cell(
      v-for="n in 49",
      :class="{ drawed: drawed(n), ok: drawed(n) && crossed(n), small: cfg.small }"
    ) {{ n }}
      .crossed(v-if="crossed(n)",:class="{ small: cfg.small }")
        svg(
          xmlns:xlink="http://www.w3.org/1999/xlink",
          xmlns="http://www.w3.org/2000/svg",
          version="1.1",
          width="100%",
          height="100%",
          viewBox="0 -48 48 48"
        )
          g(id="Layer 1", transform="scale(1 -1)")
            path(
              :fill="crossed(n)",
              stroke-width=10,
              stroke="red",
              d="M 0 0 L 47 47 M 0 47 L 47 0"
            )
  div(v-if="won && cfg.won",style="margin:1vh 4vh;display: flex; justify-content: space-between;font-size:6vw;color:black;background-color:#ccf;")
    div {{ won.winningClassShortName }}
    div {{ won.odds.toFixed(2) }} €
</template>

<script>
export default {
  props: ["checked", "sz", "draw", "id", "cfg"],
  data() {
    return {
      wins: ["6+", "6", "5+", "5", "4+", "4", "3+", "3", "2+"],
      odd: {},
      win: 0,
      name: ''
    };
  },
  computed: {
    matching() {
      let n = 0;
      if (!this.checked) return "";
      console.log('--- draw', this.draw);
      const nums = (this.draw && this.draw.drawNumbersCollection) || [];

      this.checked.forEach((i) => {
        if (nums.includes(i)) n++;
      });
      if (this.draw && this.draw.superNumber == this.sz) n += "+";
      return ''+n;
    },
    won() {
      // if (!this.draw)
      //   return null;
      const w = this.matching;
      const wc = this.wins.indexOf(''+w);
      if (wc < 0) return null;
      if (!this.draw || !this.draw.oddsCollection) return null;
      const o = this.draw.oddsCollection.find((x) => x.winningClass == wc + 1);
      if (o) return o;
      return null;
    },
  },
  mounted() {
    // this.odd = this.won();
    // console.log('mounted.1',this.draw, this.odd)
    // this.win = (this.odd && this.odd.odds) || 0;
    // this.name = (this.odd && this.odd.winningClassName) || '';
  },
  methods: {
    crossed: function (n) {
      return this.checked && this.checked.includes(n) && "#ff0000";
    },
    drawed(n) {
      return (
        (this.draw && this.draw.drawNumbersCollection &&
          this.draw.drawNumbersCollection.find((x) => x == n)) ||
        false
      );
    },
    
  },
};
</script>

<style lang="less" scoped>
.number {
  color: #048;
  font-size: 1.6vh;
}

.grid {
  border: 1px solid gray;
  padding: 1px;
  margin: 0.25vh 0.25vh;
  flex: 0 0 auto;
  color: #024;
  //   align-self: center;
  font-size: 1.6vh;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  justify-content: stretch;
  column-gap: 1px;
  row-gap: 1px;
  justify-content: center;
  align-self: center;
}
.cell {
  line-height: 3vh;
  width: 3vh;
  height: 3vh;
  aspect-ratio: 1;
  padding: 0;
  margin: 0;
  border: 1px solid gray;
  border-radius: 2px;
  position: relative;
  background-color: white;
  //   box-shadow: 2px 2px 5px #777 ;
  //   background: linear-gradient(135deg, #fff, silver);
}

.crossed {
  position: absolute;
  left: 0.3rem;
  top: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  opacity: 0.6;
}
.crossed.small {
  left: 5%;
  top: 5%;
  right: 5%;
  bottom: 5%;
}
.drawed {
  background-color: #c0e0ff;
}
.sz {
  font-size: 1.2rem;
  color: #246;
  align-self: flex-start;
  margin-left: 2rem;
}
.ok {
  background-color: #80ff80;
}
@media screen and (orientation: portrait) {
  .number {
    font-size: 6vw;
  }
  .number.small {
    font-size: 3vw;
  }
  .cell {
    line-height: 8vw;
    width: 8vw;
    height: 8vw;
    font-size: 5vw;
  }
  .cell.small {
    line-height: 3vw;
    width: 3vw;
    height: 3vw;
    font-size: 2.2vw;
  }
}
</style>
import { render, staticRenderFns } from "./Lotto.vue?vue&type=template&id=02b039ac&scoped=true&lang=pug"
import script from "./Lotto.vue?vue&type=script&lang=js"
export * from "./Lotto.vue?vue&type=script&lang=js"
import style0 from "./Lotto.vue?vue&type=style&index=0&id=02b039ac&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b039ac",
  null
  
)

export default component.exports
<template lang="pug">
.task-grid
  .header-item Tätigkeit
  .header-item 
    div 05 - 12
    icon.small(name="morning")
  .header-item 
    div 12 - 18
    icon.small(name="midday")
  .header-item 
    div 18 - 23
    icon.small(name="evening")
  .header-item 
    div 23 - 05
    icon.small(name="night")
  .header-item Hilfe
  template.group(v-for="group in cummulated")
    .group-name {{ group.name }}
    template.task-row(v-for="task in group.tasks")
      .task-name {{ task.name }}
      .task-time(v-for="time in task.times") {{ time.minutes || "" }}
      .task-help {{ task.help }}
</template>

<script>
import Icon from '@/components/Icon.vue';
export default {
  props: ["tasks"],
  components: { Icon },
  computed: {
    cummulated() {
      console.log("care grid tasks", this.tasks);
      if (this.tasks && this.tasks.length) {
        return this.tasks.map((group) => ({
          ...group,
          tasks: this.cummulateTasks(group.tasks),
        }));
      }
    },
  },
  methods: {
    categorizeTimes(start, stop) {
      const startMinutes = this.timeToMinutes(start);
      let endMinutes = this.timeToMinutes(stop);
      if (endMinutes == startMinutes)
        endMinutes++;
      const r = [
        {
          label: "Morgens",
          minutes: this.getTimeInRange(startMinutes, endMinutes, 300, 720),
        },
        {
          label: "Mittags",
          minutes: this.getTimeInRange(startMinutes, endMinutes, 720, 1080),
        },
        {
          label: "Abends",
          minutes: this.getTimeInRange(startMinutes, endMinutes, 1080, 1380),
        },
        {
          label: "Nachts",
          minutes: this.getTimeInRange(
            startMinutes,
            endMinutes,
            1380,
            300,
            true
          ),
        },
      ];
      return r;
    },
    timeToMinutes(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 60 + minutes;
    },
    getTimeInRange(start, stop, rangeStart, rangeEnd, overnight = false) {
        let diff = 0;
      if (overnight) {
        if (start < rangeEnd || stop >= rangeStart + 1440) {
          diff = Math.min(stop, rangeEnd + 1440) - Math.max(start, rangeStart);
          return diff;
        }
      } else {
        if (start < rangeEnd && stop > rangeStart) {
            diff = Math.min(stop, rangeEnd) - Math.max(start, rangeStart);
            console.log('timeInRange',start,stop,rangeStart,rangeEnd,diff);
          return diff;
        }
      }
      return 0;
    },
    getTimePeriod(time) {
      const [hours] = time.split(":").map(Number);
      if (hours >= 5 && hours < 12) return "Morgens";
      if (hours >= 12 && hours < 18) return "Mittags";
      if (hours >= 18 && hours < 23) return "Abends";
      return "Nachts";
    },
    cummulateTasks(tasks) {
      if (tasks.length < 1) return {};
      const grouped = {};
      tasks.forEach((task) => {
        const key = `${task.id}-${task.helpId}-${this.getTimePeriod(task.start)}`;
        console.log('KEY', key,task);
        if (!grouped[key]) {
          grouped[key] = {
            ...task,
            times: this.categorizeTimes(task.start, task.stop),
          };
        } else {
          grouped[key].times.forEach((time, index) => {
            time.minutes += this.categorizeTimes(task.start, task.stop)[
              index
            ].minutes;
          });
        }
      });
      return Object.values(grouped);
    },
  },
};
</script>

<style lang="less" scoped>
.task-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1px;
  width: 100%;
      text-align: left;

}
.grid-header {
  //   display: grid;
  //   grid-template-columns: repeat(7, 1fr);
  font-weight: bold;
  padding: 4px;
}
.header-item {
  background: #cde;
  padding: 4px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 2vw;
}
.group-name {
  grid-column: span 6;
  background: #ddd;
  padding: 4px;
  font-weight: bold;
  text-align: center;
}
.task-row {
  display: contents;

}
.task-name,
.task-time,
.task-help {
  padding: 4px;
  border: 1px solid #ccc;
}
.task-name {
  font-weight: bold;
}
.task-time {
    text-align: right;
}
@media print {
  .task-grid {
    font-size: 1.6vw;
    grid-template-columns: 6fr 2fr 2fr 2fr 2fr 4fr;
    gap: 4px;
  }
  .group-name {
    padding: 8px 0;
  }
}
</style>
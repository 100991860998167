<template lang="pug">
.container
  h2 Lotto Voraussage
  .predict(v-for="(p,ix) in predicts") 
    div(style="display:flex;justify-content:space-around;") 
        div {{ix+1}}.
        div Gespeichert: {{ new Date(p.timestamp).toLocaleString() }}
    .config {{description(p.config,p.date)}}
    .numberRow(v-if="p.draw")
      .predict Ziehung {{ p.date }}
      .numbers
        .number.drawed(v-for="n in p.draw.numbers") {{ n }}
        .number.super {{ p.draw.superNumber }}
    .results
        div(style="width:100%;background:#8cf;text-align:right;padding:1vw;margin:2vw 0;",@click="toggle(p.results)")
            icon.small(v-if="showing!=p.results",name="chevronDown") 
            icon.small(v-if="showing==p.results",name="chevronUp") 
        .field(v-if="showing==p.results",v-for="f in p.results")
            .hits(v-if="p.draw") Treffer: {{ f.hits }}
            .number(
                v-for="k in 49",
                :class="{ crossed: crossed(f.field, k), drawed: drawed(p.draw && p.draw.numbers, k) }"
            ) {{ k }}
    .odds(v-if="p.odds")
        template.win(v-for="w in p.odds")
            .play {{w.play}}
            .name {{w.name}}
            .winners {{w.winners}}
            .price {{w.odds.toFixed(2)}} €
        template
            div(v-for="t in 4", style="border-top:1px solid gray;")
        template.win
            .play Gesamt:
            .name
            .winners
            .price {{total(p.odds).toFixed(2)}} €
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from '@/components/Icon.vue'
export default {
    components: { Icon },
    data() {
        return {
            showing: null
        }
    },
  computed: {
    ...mapGetters({
      predicts: "lotto/predicts",
    }),
  },
  mounted() {
    this.load_predicts();
  },
  methods: {
    ...mapActions({
      load_predicts: "lotto/load_predicts",
    }),
    toggle(t) {
        if (this.showing == t)
            this.showing = null;
        else
            this.showing = t;
    },
    total(wins) {
        let sum = 0;
        wins.forEach(w => sum += w.odds);
        return sum;
    },
    crossed(f, k) {
      return f && f.includes(k);
    },
    drawed(f, k) {
      return f && f.includes(k);
    },
    description(cfg, date) {
        let s = `Die Voraussage basiert auf allen Ziehungen des Jahres${cfg.full?'':', ohne die letzte Ziehung am ' + date}.
Pro Feld werden ${cfg.retries} Versuche mit 6 Zahlen unternommen. Der Versuch, der ${cfg.mode=='hits'?'maximale Anzahl Treffer ab 3 Richtigen':'maximalen Gewinn im Zeitraum'} erzielt, wird übernommen.`;
        if (!cfg.full) {
            s += ` Der untere Abschnitt zeigt den Gewinn, der am ${date} möglich wäre.`
        }

        return s;
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  color: black;
  display: flex;
  flex-flow: column;
  font-size: 3vw;
  padding: 0 4vw;
}
.numberRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  font-size: 3.2vw;
}
.numbers {
    flex: 0 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  font-size: 3.2vw;
}
.number {
  width: 5vw;
  height: 5vw;
  line-height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  font-size: 3.0vw;
  font-weight: bold;
}
.super {
  background: #f66;
}
.crossed {
  background: #6af;
}
.drawed {
  background: #ccc;
}
.crossed.drawed {
  background: #6ff;
}
.config {
    padding: 2vw;
    font-size: 3vw;
    color: #048;
    text-align: left;
}
.results {
  display: flex;
  flex-flow: row wrap;
}
.predict {
  font-size: 4vw;
}
.hits {
  font-size: 3.6vw;
  grid-column: span 7;
  background: #eee;
  padding: 2px 4px;
}
.field {
  border: 1px solid silver;
  margin: 4px;
  display: grid;
  grid-template-columns: repeat(7, 6vw);
  padding: 0;
  text-align: left;
}
.odds {
    display:grid;
    grid-template-columns: 24vw auto auto auto;
    margin-top: 2vw;
    margin-bottom: 4vw;
    padding: 1vw;
    background: #dfd;
    font-size:3vw;
}
.win {
    // display: grid;
}
.play, .name {
    justify-self: start;
}
.price, .winners {
    justify-self: end;
}
</style>
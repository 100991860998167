<template lang="pug">
    div
        h2 Icons
        div(style="display:flex;flex-flow:row wrap;color:black;")
            div(v-for="i in Object.keys(icons)",style="width:6rem;margin:1rem;")
                icon.big(:name="i")
                .name {{i}}
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
export default {
    components: { Icon },
    computed: {
        ...mapGetters({ icons: "icons/items" }),
    }
}
</script>

<style lang="less" scoped>
.name {
    font-size: 3vw;
}
</style>
<template lang="pug">
.eurojackpot
  .drawing.noprint
    div(style="display: flex")
      icon.button(name="chevronLeft", @click="nextDraw()")
      select(v-model="selectedDate", @change="selectDate(selectedDate)")
        option(v-for="d in dates", :value="d") {{ d.toLocaleDateString("de-DE", dateOpts) }}
      icon.button(name="chevronRight", @click="prevDraw()")
      icon.small.button(name="print", @click="print()")
      icon.button.small(name="simulation",@click="simulation()")
    div(
      v-if="draw && draw.drawNumbersCollection",
      style="display: flex; padding: 1rem 0; align-items: center"
    )
      div(
        v-for="d in draw.drawNumbersCollection.filter((x) => x.drawNumberType == 0)"
      ) 
        .ball {{ d.drawNumber }}
        .shadow
      div(
        style="margin: 0 0.2rem; width: 2px; align-self: stretch; background-color: gray"
      )
      div(
        v-for="d in draw.drawNumbersCollection.filter((x) => x.drawNumberType == 1)"
      )
        .ball {{ d.drawNumber }}
        .shadow
    div(style="align-self:flex-start;display:flex;flex-flow:column;align-items:flex-start;flex:0 0 auto;font-size:1.4rem;color:black;margin:0.3rem 1rem;")
        div(v-if="lotto && lotto.game77") {{ lotto.game77.gameType.name }} {{ lotto.game77.numbers }}
        div(v-if="lotto && lotto.super6") {{ lotto.super6.gameType.name }} {{ lotto.super6.numbers }}
  .quotes
    .qrow(v-for="w in won()") 
        .name(:class="{ summed: w.border == true }") {{w.name}}
        .win(:class="{ summed: w.border == true }") {{(1.0*w.win).toFixed(2)}} €
  .fields
    div(style="font-size: 2.4rem; color: black; align-self: flex-start") Losnummer: {{ coupon.number }}
    .field(v-for="f in coupon.fields")
      EurojackpotGrid(:crossed="f.crossed", :drawed="draw", :stats="stats")
      button3d.noprint(v-if="!f.readonly")
        icon.small(name="play", @click="play(f)")
    button3d.noprint
      icon.small(name="add", @click="add()")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import EurojackpotGrid from "@/components/EurojackpotGrid.vue";
import Vue from "vue";

export default {
  components: { Icon, Button3d, EurojackpotGrid },
  data() {
    return {
      dateOpts: {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      stats: {},
      dates: [],
      selectedDate: null,
      coupon: {
        number: 8495500,
        fields: [
          {
            crossed: { nums: [1,22,28,36,37], sz: [7, 10] },
            readonly: true,
          },
          {
            crossed: { nums: [7,28,34,48,50], sz: [7, 10] },
            readonly: true,
          },
          { crossed: { nums: [6,21,31,42,49], sz: [3,9] }, readonly: true },
          { crossed: { nums: [2,9,14,23,25], sz: [2,10] }, readonly: true },
          { crossed: { nums: [6,15,17,35,38], sz: [5,10] }, readonly: true },
          { crossed: { nums: [12,23,30,31,38], sz: [4,8] }, readonly: true },
        ],
      },
      drawed: null,
    };
  },
  async mounted() {
    // this.coupon.fields = [{}];
    const t = await this.list();
    this.stats = this.items.stats;
    this.dates = this.generateDates(2022);
    this.selectedDate = this.dates[0];
    console.log("EJ selected date", this.selectedDate);
    await this.drawing(this.selectedDate.getTime());
    await this.getLotto(this.selectedDate);
  },
  computed: {
    ...mapGetters({
      items: "eurojackpot/items",
      draw: "eurojackpot/draw",
      lotto: "lotto/draw",
    }),
  },
  methods: {
    ...mapActions({
      list: "eurojackpot/list",
      drawing: "eurojackpot/drawing",
      getLotto: "lotto/drawing",
    }),
    print() {
      window.print();
    },
    simulation() {
        this.$router.push('eurojackpotSim');
    },
    async selectDate(d) {
      await this.drawing(d.getTime());
      await this.getLotto(d);
    },
    prevDraw() {
      const f = this.dates.findIndex((x) => x == this.selectedDate) + 1;
      if (f >= this.dates.length) f = 0;
      this.selectedDate = this.dates[f];
      this.selectDate(this.selectedDate);
    },
    nextDraw() {
      const f = this.dates.findIndex((x) => x == this.selectedDate) - 1;
      if (f < 0) f = this.dates.length - 1;
      this.selectedDate = this.dates[f];
      this.selectDate(this.selectedDate);
    },
    add() {
      this.coupon.fields.push({ nums:[], sz:[]});
    },
    matchFromEnd(str1, str2) {
      console.log(str1, str2);
      let matchLength = 0;
      let i = str1.length - 1;
      let j = str2.length - 1;

      // Iteriere von hinten durch beide Strings
      while (i >= 0 && j >= 0 && str1[i] == str2[j]) {
        matchLength++;
        i--;
        j--;
      }
      return matchLength;
    },
    check77() {
      if (!this.lotto || !this.lotto.game77) return "--";
      const s77 = this.lotto.game77.numbers;
      const m = this.matchFromEnd(s77, this.coupon.number + "");
      if (m < 1) return null;
      const name = this.lotto.game77.gameType.name;
      if (!this.lotto.game77.oddsCollection) return name + ": wird ermittelt";
      const won =
        { name, win: this.lotto.game77.oddsCollection[7 - m].odds };
      return won;
    },
    checks6() {
      if (!this.lotto || !this.lotto.super6) return "--";
      const s6 = this.lotto.super6.numbers;
      const m = this.matchFromEnd(s6, this.coupon.number + "");
      if (m < 1) return null;
      const name = this.lotto.super6.gameType.name;
      if (!this.lotto.super6.oddsCollection) return name + ": wird ermittelt";
      const won =
        { name, win: this.lotto.super6.oddsCollection[6 - m].odds };
      return won;
    },
    won() {
        const r = [];
        if (!this.draw.oddsCollection)
            return r;
        let sum = 0;
        this.coupon.fields.forEach(f => {
            const w = [0,0];
            f.crossed.nums.forEach(c => w[0] += (this.draw.drawNumbersCollection.find(x => x.drawNumber == c && x.drawNumberType == 0) && 1) || 0);
            f.crossed.sz.forEach(c => w[1] += (this.draw.drawNumbersCollection.find(x => x.drawNumber == c && x.drawNumberType == 1) && 1) || 0);
            const s = `${w[0]} + ${w[1]}`;
            const t = this.draw.oddsCollection.find(o => {
                // console.log('*** ', o.winningClassDescription.winningClassShortName, s, o.winningClassDescription.winningClassShortName == s)
                return o.winningClassDescription.winningClassShortName == s
            });
            // console.log('--- t', t, s);
            if (t) {
                r.push({ name:t.winningClassDescription.winningClassName, win:t.odds });
                sum += t.odds;
            }
        })
        const x77 = this.check77();
        x77 && r.push(x77) && (sum += x77.win );
        const x6 = this.checks6();
        x6 && r.push(x6) && (sum += x6.win );

        sum && r.push({ name:'Gesamt', win: sum, border:true });
        console.log('#### ', r)
        return r;
    },
    async play(f) {
      console.log("PLAY", f);
      const resp = await api.eurojackpot.random();
      Vue.set(f, "crossed", resp.data);
      // f.crossed = resp.data;
      console.log(f);
    },
    generateDates(startYear) {
      console.log("generate dates");
      const today = new Date(); // Heutiges Datum
      const result = [];
      let currentDate = new Date(Date.UTC(startYear, 0, 1)); // 1. Januar des Startjahres
      console.log("generate dates", currentDate);

      // Finde den ersten Mittwoch oder Samstag ab dem Startdatum
      while (currentDate.getDay() !== 2 && currentDate.getDay() !== 5) {
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      // Springe zwischen Mittwoch und Samstag
      while (currentDate <= today) {
        result.push(new Date(currentDate)); // Datum speichern

        // Nächster Dienstag (+3 Tage) oder Freitag (+4 Tage)
        currentDate.setUTCDate(
          currentDate.getUTCDate() + (currentDate.getUTCDay() === 2 ? 3 : 4)
        );
      }

      result.sort((a, b) => {
        return b.getTime() - a.getTime();
      });

      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.eurojackpot {
  display: flex;
}
.drawing {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.ball {
  font-size: 3rem;
  color: black;
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  // border: 1px solid black;
  border-radius: 5rem;
  margin: 0 0.5rem;
  // position: relative;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(80, 80, 80, 1) 70%,
    rgba(80, 80, 80, 1) 100%
  );
  //box-shadow: 4px 4px 5px #777;
  z-index: 1;
}
.shadow {
  z-index: 0;
  // position:absolute;
  margin-left: 0.5rem;
  margin-top: -7%;
  // top:70%;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: radial-gradient(#000f, #fff0 72%);
  height: 1rem;
  width: 5rem;
  border-radius: 50%;
}
.quotes {
    color: black;
    font-size: 1.8rem;
    flex: 0 0 auto;
    display: grid;
    grid-template-columns: 5fr 1fr;
    margin: 0.25rem 1rem;
    background-color: #cfc;
    padding: 1rem;
}
.qrow {
    display: contents;
}
.name {
    justify-self: stretch;
    text-align: left;
    padding: 0.2rem;
}
.win {
    justify-self: stretch;
    text-align: right;
    padding: 0.2rem;
}
.summed {
    border-top: 1px solid gray;
    background-color: #efe;
}
.field {
  margin: 1rem;
}
.fields {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (orientation: portrait) {
  .eurojackpot {
    flex-flow: column;
    flex: 1 0;
    overflow: auto;
  }
  .fields {
    flex: 1 0;
    overflow: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .field {
    margin-bottom: 4vw;
    padding: 2vw;
    display: flex;
    align-items: flex-start;
  }
  .button {
    margin: 0 2vw;
    padding: 1vw;
    color: black;
  }
  .ball {
    height: 10vw;
    width: 10vw;
    line-height: 10vw;
    border-radius: 50%;
    margin: 0 0.5vw;
    font-size: 5vw;
    font-weight: bold;
  }
  .shadow {
    width: 10vw;
  }
}
@media print {
  .fields {
    flex-flow: row wrap;
  }
  .noprint {
    display: none;
  }
}
</style>
<template lang="pug">
    .labeled
        .label {{caption}}
        slot
</template>

<script>
export default {
    props: ['caption']
}
</script>

<style lang="less" scoped>
.labeled {
    display:flex;
    flex-flow:column nowrap;
    align-items: flex-start;
}
.label {
    // color: gray;
}
</style>
<template lang="pug">
.container
  button3d(
    v-if="$route.name === 'tools' && (!t.local || local)",
    v-for="t in tools",
    style="margin: 2vw 4vw",
    @click="goto(t.target)",
    :title="t.info"
  ) 
    |
    div(style="display: flex; flex: 1 0; align-items: center")
      .img(:style="{ backgroundImage: 'url(' + t.img + ')' }")
      div(style="flex: 1 0; display: flex; justify-content: center") {{ t.name }}
  router-view(style="flex: 1 0; display: flex; flex-flow: column")
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";

export default {
  components: { Icon, Button3d },
  data() {
    return {
      local: false,
    };
  },
  computed: {
    ...mapGetters({
        tools: 'navi/items'
    })
  },
  methods: {
    ...mapActions({
      get_menu: "navi/menu"
    }),
    goto(t) {
      this.$router.push({ name: t });
    },
  },
  async created() {
    await this.get_menu({ area: 'tools' });
    this.local = window.location.protocol == "http:";
    console.log(this.$route, window.location, this.tools);
  },
};
</script>

<style lang="less" scoped>
.container {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  overflow: auto;
}
.grid {
  margin: 1rem;
  flex: 1 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(4fr);
  align-items: stretch;
  gap: 0.5rem;
  overflow: auto;
}
button3d {
  font-size: 1.8rem;
}
.img {
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
@media print {
  .container {
    display: block;
    height: auto !important;
  }
}
</style>
<template lang="pug">
.worktime
  .sidebar
    .timedisplay
      clock(:time="ctime", color="black", back="#8ac")
      .date {{ cdate }}
    navi
  router-view
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navi from "/src/components/worktime/Navi.vue";
import Clock from "@/components/Clock.vue";

export default {
  components: { Navi, Clock },
  data() {
    return {
      timer: null,
      ctime: 0,
      cdate: 0,
    };
  },
  async mounted() {
    this.ctime = this.gettime();
    this.cdate = this.getdate();
    this.timer = setInterval(() => {
      this.ctime = this.gettime();
      this.cdate = this.getdate();
    }, 1000);
  },
  beforeUnmount() {
    this.timer && clearInterval(this.timer);
  },
  computed: {},
  methods: {
    gettime() {
      const now = new Date();
      const secondsSinceMidnight =
        now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
      return secondsSinceMidnight * 1000;
    },
    getdate() {
      const d = new Date();
      return d.toLocaleDateString("de-DE", {
        weekday: 'short',
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.worktime {
  flex: 1 0;
  display: flex;
}
.sidebar {
  flex: 0 0;
  display: flex;
  flex-flow: column;
  background-color: #8ac;
  align-items: stretch;
}
.timedisplay {
  flex: 0 0; 
  display: flex; 
  flex-flow: column; 
  align-items: center; 
  padding: 1rem 0;
  border-bottom: 1px solid black;
}
.clock {
  // transform: scale(0.9);
  zoom: 18%;
}
.date {
  color: white;
  font-size: 1.2rem;
}
</style>
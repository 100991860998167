<template lang="pug">
.container
  .header 
    |
    div(style="flex: 1 0") Lotto
    icon.button.small(name="simulation", @click="simulation()")
  .lotto
    .grid
      div(
        style="font-size: 2.4rem; color: black; align-self: flex-start; margin: 0 4vw"
      ) Losnummer: {{ coupon.number }}
      .fields
        lotto-grid(
          v-for="(r, rix) in coupon.fields",
          :checked="r",
          :sz="coupon.sz",
          :draw="draw",
          :id="rix + 1",
          :cfg="{ grid: 1, small: 1 }"
        )
      //- div(style="font-size: 1.6rem; color: black") Superzahl {{ coupon.sz }}
    .win
      .winGrid
        template(v-for="w in check77()")
          .cell {{ w.field }}
          .cell(v-if="!w.wait") {{ w.winningClassShortName }}
          .cell(v-if="!w.wait") {{ w.numberOfWinners }}
          .cell(v-if="!w.wait") {{ (w.odds || w.jackpot || 0).toFixed(2) }} €
          .cell(v-if="w.wait",style="grid-column: span 3;") wird ermittelt
        template(v-for="w in checks6()")
          .cell {{ w.field }}
          .cell(v-if="!w.wait") {{ w.winningClassShortName }}
          .cell(v-if="!w.wait") {{ w.numberOfWinners }}
          .cell(v-if="!w.wait") {{ (w.odds || w.jackpot || 0).toFixed(2) }} €
          .cell(v-if="w.wait", style="grid-column: span 3;") wird ermittelt
        template(v-for="w in won")
          .cell {{ w.field }}
          .cell {{ w.winningClassShortName }}
          .cell {{ w.numberOfWinners }}
          .cell {{ (w.odds || w.jackpot || 0).toFixed(2) }} €
    .panel
      //button3d(style="margin: 1vh 0", @click="play()")
        icon(name="play")
      //button3d(style="margin: 1vh 0", @click="print()")
        icon(name="print")
    //- .freq
      div(style="display: flex; align-items: center", v-for="(f, ix) in freq")
        div(style="width: 2rem; text-align: right; margin-right: 1vh") {{ f.n }}
        .bar(:style="{ width: barwidth(f.f) }") 
          |
          div(style="margin-left: 1rem") {{ f.f }}
    .checked
      div(style="display: flex; margin: 0.5rem 0")
        icon.button.small(name="chevronLeft", @click="nextDraw()")
        select(v-model="selectedDate", @change="selectDate(selectedDate)")
          option(v-for="d in dates", :value="d") {{ new Date(1 * d).toLocaleDateString("de-DE", dateOpts) }}
        icon.button.small(name="chevronRight", @click="prevDraw()")
      div(style="display: flex; padding: 0.25rem 0; align-items: center")
        div(v-for="d in draw.drawNumbersCollection")
          .ball {{ d }}
          .shadow
        div(
          style="margin: 0 0.2rem; width: 2px; align-self: stretch; background-color: gray"
        )
        div
          .ball {{ draw.superNumber }}
          .shadow
      div(
        style="font-size: 2rem; display: flex; flex-flow: column; align-items: flex-start"
      )
        div(v-if="draw && draw.game77") Spiel77 {{ draw.game77.numbers }}
        div(v-if="draw && draw.super6") Super6 {{ draw.super6.numbers }}
    //-   div(
    //-     v-for="c in items",
    //-     style="display: flex; margin: 0.25rem 0.5rem; border: 1px solid gray; cursor: pointer;flex:0 0 auto;",
    //-     @click="current = c"
    //-   )
    //-     div(style="padding: 2px 1rem") {{ c.date }}
    //-     div(
    //-       style="padding: 2px 4px; width: 2rem; text-align: right; color: gray",
    //-       v-for="n in c.nums"
    //-     ) {{ n }}
      //- div(style="padding: 2px 2rem") {{ c.a.length }}
      //- div(
      //-   style="padding: 2px 4px; width: 2rem; text-align: right; color: gray",
      //-   :style="{ color: color(c.a.length) }",
      //-   v-for="a in c.a"
      //- ) {{ a }}
    //- div(style="overflow:auto;font-size:1.4vh;")
      div(v-for="i in items") {{i}}
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import LottoGrid from "@/components/LottoGrid.vue";

export default {
  components: { Icon, Button3d, LottoGrid },
  data() {
    return {
      dateOpts: {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      wins: ["6+", "6", "5+", "5", "4+", "4", "3+", "3", "2+"],
      selectedDate: 0,
      sum: 0,
      max: 0,
      dates: [],
      freq: {},
      coupon: {
        number: 1971735,
        fields: [
          [2, 6, 8, 10, 27, 15],
          [7, 14, 26, 32, 36, 40],
          [18, 20, 32, 34, 46, 48],
          [25, 33, 40, 43, 45, 48],
          [29, 32, 35, 45, 47, 48],
          [12, 24, 34, 37, 40, 46],
          [9, 16, 27, 41, 30, 49],
          [9, 48, 36, 49, 33, 13],
          [7, 25, 15, 35, 18, 33],
          [1, 23, 36, 48, 11, 13],
          [23, 26, 40, 48, 36, 28],
          [16, 5, 47, 27, 30, 12],
        ],
      },
      allItems: [],
      draw: {},
    };
  },
  computed: {
    ...mapGetters({
      items: "lotto/items",
    }),
    won() {
      console.log("check win");
      const wins = [];
      this.coupon.fields.forEach((f, ix) => {
        console.log("field", f);
        const w = this.matching(f);
        const wc = this.wins.indexOf("" + w);
        console.log("wc=", w, wc);
        if (wc >= 0) {
          if (!this.draw.oddsCollection) return null;
          console.log("odds=", this.draw.oddsCollection);
          const o = this.draw.oddsCollection.find(
            (x) => x.winningClass == wc + 1
          );
          if (o) wins.push({ ...o, field: ix + 1 });
        }
      });
      console.log("wins=", wins);
      return wins;
    },
  },
  methods: {
    ...mapActions({
      load: "lotto/load",
    }),

    matching(checked) {
      let n = 0;
      if (!checked) return "";
      const nums = this.draw.drawNumbersCollection || [];

      console.log("checked", checked, nums);
      checked.forEach((i) => {
        if (nums.includes(i)) n++;
      });
      console.log("matching", this.draw && this.draw.superNumber, checked);
      const sz = this.coupon.number % 10;
      console.log('Check SZ', this.coupon.number, sz, this.draw.superNumber);
      if (this.draw && this.draw.superNumber == sz) n += "+";
      console.log("matched", n);
      return "" + n;
    },
    simulation() {
      this.$router.push({ name: "lottoSim" });
    },
    prevDraw() {
      const f = this.dates.findIndex((x) => x == this.selectedDate) + 1;
      if (f >= this.dates.length) f = 0;
      this.selectDate(this.dates[f]);
    },
    nextDraw() {
      const f = this.dates.findIndex((x) => x == this.selectedDate) - 1;
      if (f < 0) f = this.dates.length - 1;
      this.selectDate(this.dates[f]);
    },
    matchFromEnd(str1, str2) {
      console.log(str1, str2);
      let matchLength = 0;
      let i = str1.length - 1;
      let j = str2.length - 1;

      // Iteriere von hinten durch beide Strings
      while (i >= 0 && j >= 0 && str1[i] == str2[j]) {
        matchLength++;
        i--;
        j--;
      }

      return matchLength;
    },
    //           div {{w.field}}
    //       div {{w.winningClassShortName}}
    //       div {{w.numberOfWinners}}
    //       div {{(w.odds||w.jackpot).toFixed(2)}} €
    check77() {
      if (!this.draw || !this.draw.game77) return [];
      const s77 = this.draw.game77.numbers;
      const m = this.matchFromEnd(s77, this.coupon.number + "");
      if (m < 1) return [];
      const name = this.draw.game77.name;
      if (!this.draw.game77.oddsCollection)
        return [
          {
            field: name,
            wait: 1
          },
        ];
      console.log('S77', m, 6-m,this.draw.super6.oddsCollection);
      const w = [{ ...this.draw.game77.oddsCollection[7 - m], field: name }];
      return w;
    },
    checks6() {
      if (!this.draw || !this.draw.super6) return [];
      const s6 = this.draw.super6.numbers;
      const m = this.matchFromEnd(s6, this.coupon.number + "");
      if (m < 1) return [];
      const name = this.draw.super6.name;
      if (!this.draw.super6.oddsCollection)
        return [
          {
            field: name,
            wait: 1
          },
        ];
      console.log('S6', m, 6-m,this.draw.super6.oddsCollection);
      const w = [{ ...this.draw.super6.oddsCollection[6 - m], field: name }];
      return w;
    },
    selectDate(d) {
      this.selectedDate = d;
      this.getDrawing(d);
      return this.won;
    },
    color(n) {
      const c = ["", "", "#ccc", "#c86", "#864", "#642", "#420"];
      console.log("COLOR", n, c[n]);
      return c[n];
    },
    print() {
      window.print();
    },
    barwidth(n) {
      return (200 * n) / this.max + "px";
    },
    check(sel) {
      const found = [];
      this.items.forEach((t) => {
        const a = t.oddsCollection.filter((value) => sel.nums.includes(value));
        if (a.length > 2) {
          found.push({ ...t, a });
        }
      });
      found.sort((a, b) => {
        return a.drawDate - b.drawDate;
      });
      this.checked = found;
    },
    async getDrawing(date) {
      this.draw = this.items.find((x) => x.drawDate == date);
    },
  },
  async mounted() {
    await this.load();
    this.dates = this.items.map((x) => x.drawDate);
    this.selectDate(this.dates[0]);
  },
};
</script>

<style lang="less" scoped>
.container {
  font-size: 4vh;
  overflow: auto;
  flex: 1 0;
  display: flex;
  flex-flow: column;
}
.lotto {
  display: flex;
  flex: 1 0;
  justify-items: flex-start;
  align-self: center;
  overflow: auto;
}
.grid {
  flex: 1 0;
  overflow: auto;
  display: grid;
  display: flex;
  flex-flow: column;
  background-color: #e8f0f8;
  padding: 1rem 0;
  justify-items: center;
}
.fields {
  flex: 1 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
}
.freq {
  padding: 0 4vh;
  display: flex;
  flex-flow: column;
  margin: 0 4vh;
  flex: 0 0 auto;
  overflow: auto;
  font-size: 1.4vh;
  color: black;
  align-self: stretch;
  align-items: flex-start;
}
.bar {
  // height:20px;
  text-align: left;
  margin: 2px 0;
  padding: 4px 0;
  background-color: #88cc88;
  flex: 0 0 auto;
}
.checked {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  overflow: auto;
  font-size: 1.8vh;
  color: black;
  overflow: auto;
  padding: 1vw;
  margin: 0;
  overflow: hidden;
  border: 1px solid gray;
}
.ball {
  font-size: 3rem;
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  // border: 1px solid black;
  border-radius: 5rem;
  margin: 0 0.5rem;
  // position: relative;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(80, 80, 80, 1) 70%,
    rgba(80, 80, 80, 1) 100%
  );
  //box-shadow: 4px 4px 5px #777;
  z-index: 1;
}
.shadow {
  z-index: 0;
  // position:absolute;
  margin-left: 0.5rem;
  margin-top: -7%;
  // top:70%;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: radial-gradient(#000f, #fff0 72%);
  height: 1rem;
  width: 5rem;
  border-radius: 50%;
}
.win {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  background-color: #efe;
  padding: 1rem;
  margin: 1rem 0;
  align-self: stretch;
}
@media screen and (orientation: portrait) {
  .header {
    font-size: 4vw;
    color: #26a;
    display: flex;
    flex-flow: row;
    margin: 2vw 4vw;
  }
  .drawDate {
    font-size: 5vw;
  }
  select {
    font-size: 5vw;
    margin: 0 1vw;
  }
  .lotto {
    display: flex;
    align-items: center;
    overflow: auto;
    flex-flow: column;
    flex-direction: column-reverse;
  }
  .grid {
    flex: 1 0;
    overflow: auto;
    grid-template-columns: 1fr;
    align-self: stretch;
  }
  .winGrid {
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 3fr;
    font-size: 3vw;
    gap: 1px;
    color: black;
    margin: 0 4vw;
  }
  .cell {
    padding: 1vw;
    text-align:right;
    white-space: nowrap;
    border:1px solid silver;
  }
  .ball {
    height: 10vw;
    width: 10vw;
    line-height: 10vw;
    border-radius: 50%;
    margin: 0 0.5vw;
    font-size: 5vw;
    font-weight: bold;
  }
  .shadow {
    width: 10vw;
  }
}
@media print {
  .panel,
  .freq {
    display: none;
  }
  .ball {
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid black;
    border-radius: 1rem;
    margin: 0 0.2rem;
  }
}
</style>
<template lang="pug">
.content
  div Lotto Simulation
  .params
    labeled(caption="Daten ab Jahr")
      select.year(v-model="dataYear", @change="reset()")
        option(v-for="y in years", :value="y") {{ y }}
    labeled(caption="Spiel ab Jahr")
      select.year(v-model="playYear", @change="reset()")
        option(v-for="y in years", :value="y") {{ y }}
    labeled(caption="Felder")
      select.fields(v-model="fields", @change="reset()")
        option(v-for="c in 120") {{ c }}
    icon.button.small(name="play", @click="runSimulation()")
    div(style="flex: 1 0")
    icon.button.small(name="chevronUp", @click="go('lotto')")

  div(
    v-if="simulated && simulated.total",
    style="display: flex; flex-flow: column; overflow: auto"
  )
    div(style="flex: 0 0 auto; display: flex; margin: 0 4vw") 
      |
      div(style="width: 10rem; text-align: right") {{ totalCount }}
      div(
        style="width: 10rem; text-align: right",
        :style="{ color: totalWon < totalSpent ? 'red' : 'green' }"
      ) {{ totalWon.toFixed(2) }}
      div(style="flex: 1 0; text-align: right; padding: 0 2vw") Gewinn
      div(
        style="width: 20vw; text-align: right",
        :style="{ color: simulated.total < simulated.spent ? 'red' : 'green' }"
      ) {{ simulated.total.toFixed(2) }} €
    div(style="display: flex; margin: 0 4vw")
      div(style="width: 10rem; text-align: right") 
      div(style="width: 10rem; text-align: right") {{ totalSpent.toFixed(2) }}
      div(style="flex: 1 0; text-align: right; padding: 0 2vw") Kosten
      div(style="width: 20vw; text-align: right") {{ simulated.spent.toFixed(2) }} €
    div(style="display: flex; margin: 0 4vw")
      div(style="width: 10rem; text-align: right") 
      div(
        style="width: 10rem; text-align: right",
        :style="{ color: totalWon < totalSpent ? 'red' : 'green' }"
      ) {{ (totalWon - totalSpent).toFixed(2) }}
      div(style="flex: 1 0; text-align: right; padding: 0 2vw") Netto
      div(
        style="width: 20vw; text-align: right",
        :style="{ color: simulated.total < simulated.spent ? 'red' : 'green' }"
      ) {{ (simulated.total - simulated.spent).toFixed(2) }} €
    div {{ stats.max.toFixed(2) }} / {{ stats.winCount }} / {{ stats.drawCount }} / {{ stats.perDay.toFixed(2) }}
    div(
      v-if="simulated.weights",
      style="flex: 0 0 auto; position: relative; height: 124px; width: 80%; display: flex; align-items: flex-end; align-self: center; overflow: auto; border: 1px solid silver; margin: 8px 0; padding: 4px"
    )
      div(
        v-for="(w, n) in weights",
        style="position: absolute; bottom: 20px; width: 16px; background-color: #a0a8af",
        :style="{ height: w + 'px', left: n * 18 - 10 + 'px' }"
      )
      div(
        v-for="(w, n) in crossed",
        style="position: absolute; bottom: 20px; width: 4px; background-color: #246",
        :style="{ height: w * 10 + 'px', left: n * 18 - 10 + 'px' }"
      )
        div(
          style="position: absolute; bottom: 0; left: 0; width: 4px; background-color: #446688",
          :style="{ height: crossed[w.num] * 10 + 'px' }"
        )
      div(
        v-for="w in 49",
        style="position: absolute; bottom: 8px; width: 16px; font-size: 2.6vw",
        :style="{ left: w * 18 - 10 + 'px' }"
      ) {{ w }}
      div(
        v-for="w in simulated.weights.fn",
        style="position: absolute; top: 8px; width: 40px; font-size: 2.2vw; color: black; transform: rotate(-90deg)",
        :style="{ left: w.num * 18 - 24 + 'px' }"
      ) {{ w.f }}

  .grid(v-if="simulated.ticket && simulated.ticket.fields")
    lotto-grid(
      v-for="r in simulated.ticket.fields",
      :checked="r",
      :draw="draw",
      :cfg="{ grid: 1, small: 1 }"
    )
  div(style="flex: 1 0; overflow: auto")
    .winblock(v-for="w in simulated.wins")
      .windate(@click="showDraw(w.drawDate)") {{ w.date }}
      .win(v-for="x in w.win") 
        |
        div {{ x.winningClassShortName }}
        div(style="text-align: right") {{ x.numberOfWinners }}
        div(style="text-align: right") {{ (x.odds || 0).toFixed(2) }} €
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import LottoGrid from "@/components/LottoGrid.vue";
import Labeled from "@/components/Labeled.vue";
export default {
  components: { Icon, Button3d, LottoGrid, Labeled },
  data() {
    return {
      years: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
      dataYear: 2025,
      playYear: 2025,
      fields: 12,
      totalCount: 0,
      totalWon: 0,
      totalSpent: 0,
      stats: {
        max: 0,
        dayCount: 0,
        drawCount: 0,
        perDay: 0,
      },
      weights: {},
      crossed: {},
      draw: null,
      ticket: {
        number: 1971735,
        ez: 5,
        fields: [
          { nums: [8, 16, 29, 43, 18, 25], ez: [2] },
          { nums: [45, 26, 39, 31, 10, 30], ez: [8] },
          { nums: [18, 3, 10, 35, 25, 33], ez: [2] },
          { nums: [9, 30, 22, 13, 35, 12], ez: [6] },
          { nums: [27, 47, 25, 2, 16, 9], ez: [2] },
          { nums: [3, 30, 48, 25, 49, 27], ez: [2] },
          { nums: [28, 45, 30, 16, 6, 27], ez: [5] },
          { nums: [30, 6, 10, 13, 41, 3], ez: [2] },
          { nums: [25, 49, 27, 9, 34, 39], ez: [2] },
          { nums: [25, 13, 18, 2, 22, 40], ez: [8] },
          { nums: [35, 33, 16, 30, 45, 29], ez: [8] },
          { nums: [41, 14, 13, 10, 2, 25], ez: [6] },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      simulated: "lotto/simulated",
      items: "lotto/items",
    }),
  },
  async mounted() {
    await this.load();
    // this.dates = this.items.map((x) => x.drawDate);
    // this.selectDate(this.dates[0]);
  },
  methods: {
    ...mapActions({
      simulation: "lotto/simulation",
      load: "lotto/load",
    }),
    go() {
      this.$router.push({ name: "lotto" });
    },
    showDraw(drawDate) {
      this.draw = this.items.find((x) => x.drawDate == drawDate);
      console.log("draw", drawDate, this.draw, this.items.length);
    },
    async writeClipboardText(text) {
      console.log(navigator);
      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
        console.error(error.message);
      }
    },
    getStats(wins) {
      const stats = { max: 0, winCount: 0, drawCount: 0, perDay: 0 };
      stats.winCount = wins.length;
      stats.drawCount = this.simulated.draws;
      wins.forEach((w) => {
        let m = 0;
        w.win.forEach((x) => (m += x.odds));
        if (m > stats.max) stats.max = m;
      });
      stats.perDay =
        (this.simulated.total - this.simulated.spent) / wins.length;
      this.stats = { ...stats };
    },
    async runSimulation() {
      this.draw = null;
      const cfg = {
        dataYear: this.dataYear,
        playYear: this.playYear,
        number: 1971735,
        count: this.fields,
        power: 2,
      }; //, data:this.ticket.fields };
      console.log("run cfg", cfg);
      await this.simulation(cfg);
      this.totalCount++;
      this.totalWon += this.simulated.total;
      this.totalSpent += this.simulated.spent;
      this.getStats(this.simulated.wins);
      this.weights = {};
      let max = 0;
      const w = this.simulated.weights.fn;
      w.forEach((k) => {
        if (k.f > max) max = k.f;
      });
      w.forEach((k) => (this.weights[k.num] = (k.f * 100) / max));
      console.log(max, w, this.weights);
      this.crossed = {};
      this.simulated.ticket.fields.forEach((f) => {
        f.forEach((n) => (this.crossed[n] = (this.crossed[n] || 0) + 1));
      });
      console.log(this.crossed);
      //   this.writeClipboardText(JSON.stringify(this.simulated.ticket.fields, null, 4));
    },
    reset() {
      this.totalCount = 0;
      this.totalSpent = 0;
      this.totalWon = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  color: black;
  font-size: 4vw;
  overflow: auto;
}
.params {
  display: flex;
  flex-flow: row;
  align-self: stretch;
  margin: 3vw 5vw;
  align-items: center;
}
.labeled {
  margin: 0 2vw;
  color: #666;
  font-size: 3vw;
}
.year {
  font-size: 4vw;
  padding: 0.25vw 0.5vw;
}
.fields {
  width: 6rem;
  text-align: right;
  font-size: 4vw;
  padding: 0.25vw 0.5vw;
}
.grid {
  flex: 1 0;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: center;
  overflow: auto;
}
.winblock {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  margin: 1vw 4vw;
}
.windate {
  text-align: left;
  background-color: #e0e8ef;
  padding: 1vw 4vw;
}
.win {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>